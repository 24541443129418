/*
 * @Descripttion: *
 * @version: *
 * @Author: Fengshi
 * @Date: 2020-11-20 14:43:09
 * @LastEditors: Fengshi
 * @LastEditTime: 2020-11-20 15:54:54
 */
import AgentDetail from './AgentDetail.vue'
export default AgentDetail
