/*
 * @Descripttion: *
 * @version: *
 * @Author: Fengshi
 * @Date: 2020-11-20 14:43:06
 * @LastEditors: Mingkit
 * @LastEditTime: 2021-01-06 14:47:45
 */
import DetailTable from './DetailTable'
export default {
  name: 'AgentDetail',
  components: {
    DetailTable
  },
  data () {
    return {
      form: {
        agent_name: '',
        user_list: [],
        agent_id: null
      }
    }
  },
  methods: {
    async init (id) {
      const data = await this.$wPost('/admin/agent/get.do', { id })
      this.form = Object.assign({}, this.form, data)
    },
    edit (form) {
      this.$router.push({ path: `/admin/agent/edit/${form.agent_id}` })
    }
  },
  mounted () {
    const id = this.$route.params.id
    this.init(id)
  }
}
